<template>
  <div>
    <b-modal
      v-model="showAlertLogin"
    >{{ textAlertLogin }}
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalAlertLogin',
  props: {
    showAlertLogin: {
      type: Boolean,
    },
    textAlertLogin: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    }
  },
  methods: {
    handleOk() {
      // this.$store.commit('SHOW_ALERT_LOGIN', false)
    },
  },
}
</script>

<style scoped>

</style>
