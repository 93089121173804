import { axiosInstance, axiosInstanceClient } from '@/helpers/axiosInstance'

export default {
  // eslint-disable-next-line consistent-return,no-empty-pattern
  async getListOrder({ commit, state }, {}) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      console.log('state.accessToken getListOrder',sessionStorage.getItem('accessToken'))
      const merchant = JSON.parse(sessionStorage.getItem('MERCHANT'))
      console.log(merchant)
      return await axiosInstance.get(`/api/v1/orders?merchant_id=${merchant.merchant_id}&limit=1000`, { headers })
    } catch (error) {
      console.log(error)
      return false
    }
  },
}
