<template>
  <div>
    <b-modal
      v-model="showUpdateBanner"
      @ok="handleOk"
    >
      You need to update banner now !
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalUpdateBanner',
  props: {
    showUpdateBanner: {
      type: Boolean,
    },
  },
  data() {
    return {

    }
  },
  methods: {
    handleOk() {
      this.$store.commit('SHOW_UPDATE_BANNER', false)
      this.$router.push('/apps/setting/banner')
    },
  },
}
</script>

<style scoped>

</style>
