<template>
  <div>
    <b-modal
      v-model="showUpdateTimeOpen"
      @ok="handleOk"
    >
      You need to update the opening time now !
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalUpdateTimeOpen',
  props: {
    showUpdateTimeOpen: {
      type: Boolean,
    },
  },
  data() {
    return {

    }
  },
  methods: {
    handleOk() {
      this.$store.commit('SHOW_UPDATE_TIME_OPEN', false)
      this.$router.push('/apps/setting/time-open')
    },
  },
}
</script>

<style scoped>

</style>
